body{
	font-family: $body-font;
	color: $licht-bruin;
	background-color: $bruin;
}



h1{
	color: $oranje;
	font-size: 33px;
}
img{
	max-width: 100%;
}

.btn{
	background-color: $oranje;
	color: #fff;
	padding: 5px;
	line-height: 1;
}

table{
	tr{
		border-bottom: $oranje 1px solid;
		&:last-child{
			border-bottom: none;
		}
	}
	td{
		padding: 10px;
	}
}

.main{
	padding: 0 !important;
}



.mfp-arrow-right:before, .mfp-arrow-left:before{
	border: none;
}




@media (min-width: 768px){
	.container {
	    width: 730px;
	}
}
@media (min-width: 890px){
	.container {
	    width: 830px;
	}
}
@media (min-width: 992px){
	.container {
	    width: 950px;
	}
}
@media (min-width: 1200px){
	.container {
	    width: 1150px;
	}
}


