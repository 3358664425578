// Glyphicons font path
$icon-font-path:        "../fonts/";

// Grid settings
$main-sm-columns:       12;
$sidebar-sm-columns:    4;

// Font

$body-font:					"proxima-nova" arial, sans-serif;
$extra-font:				"quimby-mayoral", arial, sans-serif;



// Colors
$brand-primary:         #9d8f88;

$white:					#fff;//#efeee4;


$bruin: 				#372f2a;
$bg-bruin:				#4b433f;
$licht-bruin:			#9d8f88;
$border-bruin:			#554a43;
$footer-bruin:			#6b625d;

$oranje:				#ca4316;


