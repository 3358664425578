footer{
	margin-top: 45px;
	background: #fff;
	color: $footer-bruin;
	padding: 45px 0;
	.breadcrumb{
		background: #fff;
		padding: 0;
		margin: 0;
		margin-top: 10px;
	}
	.logo{
		background: url('../images/robettes-footer.png');
		width: 75px; height: 46px;
		background-size: 75px 46px ;
		display: block;
		margin-right: 20px;
		margin-top: -7px;
		float: left;
		span{
		    position: absolute;
		    left: -999999px;
		}

	}
}


.breadcrumb>li+li:before{
	content: "- ";
	color: $bruin;
}



@media (min-width: 768px){

	.right-side{
		text-align: right;
	}
	footer{
		.breadcrumb{
			margin-top: 0;
		}
	}
}