.intro-txt{
	background: $white;
	color: $bruin;
	padding: 25px;
	h1{
		margin-top: 0;
	}
	em{
		font-family: $extra-font;
		font-size: 1.5em;
		text-align: right;
		//display: block;
	}
}
.intro-img{
	padding:0;
}

.locatie{
	background-color: $white;
	color: $bruin;
	padding: 15px;
	.container{
		padding: 0;
	}
}
#map-canvas{
	height: 350px;
	border-radius: 3px;
}

.open{
	background-color: $bg-bruin;
	color: $white;
	padding: 15px;
	padding-bottom: 35px;
	.container{
		padding: 0;
	}
}

.menukaart{
	background-color: $bg-bruin;
	padding: 15px;

	.container{
		padding: 0;
	}
	h1{
		margin-bottom: 0;
	}
	em{
		//color: #fff;
	}
}
.kaart{
	background-color: $bruin;
	padding: 10px;
	padding-bottom: 20px;
	margin-top: 20px;
	margin-bottom: 25px;
	.gerecht{
		border-bottom: 1px solid $oranje;
		padding: 10px;
		width: 100%;

		-webkit-column-break-inside: avoid; /* Chrome, Safari */
	    page-break-inside: avoid;           /* Theoretically FF 20+ */
	    break-inside: avoid-column;         /* IE 11 */
	    display:table;                      /* Actually FF 20+ */
	    &:last-child{
			//border-bottom-color: $bruin;
		}
		.title, .price{
			color: #fff;
		}
		.byline{
			display: block;
		}
	}
}


.sfeer{
	padding-top: 25px;
}

.galerij{
	margin-top: 45px;
	.img{
		margin-bottom: 30px;
	}
}






@media (min-width: 768px){

	.kaart{
		.gerecht{
			.byline{
				display: inline;
			}
		}
	}

	.intro-txt{
		position: absolute;
		z-index: 50;
		padding: 25px;
		margin-top: 25px;
		max-width: 350px;
	}


	.locatie, .open{
		padding: 25px;
	}
	.home
	.menukaart{
		padding-top: 110px;
		padding-bottom: 30px;
		margin-top: -45px;
	}

}

@media (min-width: 890px){
	.intro-txt{
	   padding: 35px;
	   max-width: 320px;
	}
}
@media (min-width: 1024px){

	.kaart{
		-webkit-column-count: 2; /* Chrome, Safari, Opera */
	    -moz-column-count: 2; /* Firefox */
	    column-count: 2;
	}

}

@media (min-width: 1200px){



	.intro-txt{
		padding: 45px;
		margin-top: 45px;
		max-width: 400px
	}

	.home
	.menukaart{
		padding-top: 60px;
	}
}