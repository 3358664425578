header{
	.container{
		position: relative;
	}
}

#menu-menu {
	li{
		display: inline-block;
		a{
			color: $licht-bruin;
			padding: 10px 3px;
		}
	}
}
.nav-primary{
	margin-bottom: 25px;
	ul{
		padding-left: 0;
		text-align: center;
		li{
			display: inline;
			list-style: none;
			&:after{
				content: " — ";

			}
			&:last-child{
				&:after{
					content: "";
				}
			}
		}
	}
}

.brand{
    background: url('../images/robettes-logo.png');
    width: 200px; height: 122px;
    background-size: 200px 122px ;
    display: block;
    margin: 40px auto;

    span{
        position: absolute;
        left: -999999px;
    }
}


@media (min-width: 768px){

	.nav-primary{
		position: absolute;
		top: 35px;
		left: 0;
		ul{
			text-align: left;
			li{
				display: block;
				margin-bottom: 5px;
				a{

					border-bottom: 1px solid $border-bruin;
					&:hover{
						text-decoration: none;
						border-bottom-color: $licht-bruin;
						color: #fff;
					}
				}
				&:after{
					content: " ";

				}
			}
		}
	}
}
